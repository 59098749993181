/* requires:
polyfill.js
*/

const paginationObject = {
  itemsPerPage: 10,
  currentPage: 0,
  paginationData: document.querySelectorAll('#article-container > div'),

  init: () => {
    paginationObject.currentPage = paginationObject.getQueryString('page')
      ? Number(paginationObject.getQueryString('page'))
      : null || 1;
    paginationObject.getPaginationData();
    paginationObject.initWindowResize();
  },

  getQueryString(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  initWindowResize: () => {
    window.addEventListener('resize', () => {
      paginationObject.createPaginationBtns(paginationObject.paginationData);
    });
  },

  getPaginationData: () => {
    if (!paginationObject.currentPage) {
      const newRelativePathQuery = window.location.pathname + '?page=' + 1;
      history.pushState(null, '', newRelativePathQuery);
    }
    paginationObject.paginate();
  },

  paginate: () => {
    const startIndex = (paginationObject.currentPage - 1) * paginationObject.itemsPerPage;
    const endIndex = startIndex + paginationObject.itemsPerPage;

    if (paginationObject.paginationData.length > paginationObject.itemsPerPage) {
      for (let i = 0; i < paginationObject.paginationData.length; i++) {
        if (i >= startIndex && i < endIndex) {
          paginationObject.paginationData[i].classList.remove('d-none');
        } else {
          paginationObject.paginationData[i].classList.add('d-none');
        }
      }
      paginationObject.createPaginationBtns(paginationObject.paginationData);
    }
  },

  createPaginationBtns: (paginationData) => {
    // if there are less items than the items per page, don't create pagination
    if (paginationData.length <= paginationObject.itemsPerPage) {
      return;
    }
    // create buttons
    const numOfPages = Math.ceil(paginationData.length / paginationObject.itemsPerPage);
    let paginationNumberButtons = '';

    switch (true) {
      case window.matchMedia('(max-width: 576px)').matches: // 1-2
        if (numOfPages <= 3) {
          for (let i = 1; i <= numOfPages; i++) {
            paginationNumberButtons += `
            <li class="page-item">
              <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
            </li>
            `;
          }
        } else {
          if (paginationObject.currentPage <= 2) {
            for (let i = 1; i <= 3; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          } else if (paginationObject.currentPage >= numOfPages - 1) {
            for (let i = numOfPages - 2; i <= numOfPages; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          } else {
            for (let i = paginationObject.currentPage - 1; i <= paginationObject.currentPage + 1; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          }
        }
        break;
      case window.matchMedia('(max-width: 992px)').matches: // 1-5
        if (numOfPages <= 5) {
          for (let i = 1; i <= numOfPages; i++) {
            paginationNumberButtons += `
            <li class="page-item">
              <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
            </li>
            `;
          }
        } else {
          if (paginationObject.currentPage <= 2) {
            for (let i = 1; i <= 5; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          } else if (paginationObject.currentPage >= numOfPages - 1) {
            for (let i = numOfPages - 4; i <= numOfPages; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          } else {
            for (let i = paginationObject.currentPage - 2; i <= paginationObject.currentPage + 2; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          }
        }
        break;
      default: // 1-10
        if (numOfPages <= 10) {
          for (let i = 1; i <= numOfPages; i++) {
            paginationNumberButtons += `
            <li class="page-item">
              <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
            </li>
            `;
          }
        } else {
          if (paginationObject.currentPage <= 4) {
            for (let i = 1; i <= 9; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          } else if (paginationObject.currentPage >= numOfPages - 3) {
            for (let i = numOfPages - 8; i <= numOfPages; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          } else {
            for (let i = paginationObject.currentPage - 4; i <= paginationObject.currentPage + 4; i++) {
              paginationNumberButtons += `
              <li class="page-item">
                <button class="page-link page-numbers py-2 px-3 text-dark fs-5 mx-1 rounded-1" data-page="${i}">${i}</button>
              </li>
              `;
            }
          }
        }
    }

    // create pagination nav
    const paginationNav = `
      <nav class="location-pagination mt-4">
        <ul class="pagination pagination-sm justify-content-center">
          <li class="page-item">
            <button class="page-link py-2 px-3 text-dark fs-5 me-1 rounded-1" aria-label="Previous" data-page="page-prev">
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          ${paginationNumberButtons}
          <li class="page-item">
            <button class="page-link py-2 px-3 text-dark fs-5 ms-1 rounded-1" aria-label="Next" data-page="page-next">
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>`;
    // add pagination nav to page
    const pageContentContainer = document.querySelector('#article-container');
    if (pageContentContainer.querySelector('.location-pagination')) {
      pageContentContainer.querySelector('.location-pagination').remove();
    }
    const paginationNavContainer = document.createElement('div');
    pageContentContainer.appendChild(paginationNavContainer);
    paginationNavContainer.outerHTML = paginationNav;

    // add event listeners to pagination buttons
    paginationObject.addPaginationBtnEventListeners(numOfPages);
    // update canonical links
    paginationObject.updateCanonicalLinks(numOfPages);
  },

  updateCanonicalLinks: (numOfPages) => {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    const currentPage = paginationObject.currentPage;
    // Get canonical link href and split into base and page number
    const canonicalLinkHref = canonicalLink.getAttribute('href');
    const [canonicalLinkBase, queryStringPageNumber] = canonicalLinkHref.split('?page=');

    // Update canonical link href
    if (!queryStringPageNumber) {
      const newHref = `${canonicalLinkBase}`;
      canonicalLink.setAttribute('href', newHref);
    } else {
      if (currentPage != queryStringPageNumber) {
        const newHref = `${canonicalLinkBase}?page=${currentPage}`;
        canonicalLink.setAttribute('href', newHref);
      }
    }

    // Update prev link href
    const prevLink = document.querySelector('link[rel="prev"]');
    const prevLinkHref = prevLink.getAttribute('href');
    const prevLinkBase = prevLinkHref.split('?page=')[0];
    if (currentPage != 1) {
      const newHref = `${prevLinkBase}?page=${currentPage - 1}`;
      prevLink.setAttribute('href', newHref);
    } else {
      prevLink.setAttribute('href', prevLinkBase);
    }

    // Update next link href
    const nextLink = document.querySelector('link[rel="next"]');
    const nextLinkHref = nextLink.getAttribute('href');
    const nextLinkBase = nextLinkHref.split('?page=')[0];
    if (currentPage != numOfPages) {
      const newHref = `${nextLinkBase}?page=${currentPage + 1}`;
      nextLink.setAttribute('href', newHref);
    } else {
      nextLink.setAttribute('href', nextLinkBase);
    }

    // Update canonical, prev, and next links using helper function
    if (canonicalLink !== null) {
      const updateQueryStringParameter = (uri, key, value) => {
        const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        const separator = uri.indexOf('?') !== -1 ? '&' : '?';
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + '=' + value + '$2');
        } else {
          return uri + separator + key + '=' + value;
        }
      };

      // Update canonical, prev, and next links
      const currentHref = window.location.href;
      // if page number is not in url, keep canonical link href the same
      if (!queryStringPageNumber) {
        canonicalLink.href = currentHref;
        // if page number is in url, update canonical link href
      } else {
        canonicalLink.href = updateQueryStringParameter(currentHref, 'page', currentPage);
      }
      // if on last page, set next link href to base url
      if (currentPage == numOfPages) {
        nextLink.href = '';
      } else {
        nextLink.href = updateQueryStringParameter(currentHref, 'page', currentPage + 1);
      }
      // if on first page, set prev link href to base url
      if (currentPage == 1) {
        prevLink.href = '';
      } else {
        prevLink.href = updateQueryStringParameter(currentHref, 'page', currentPage - 1);
      }
    }
  },

  addPaginationBtnEventListeners: (numOfPages) => {
    const paginationNumbers = document.querySelectorAll('.page-numbers');
    const paginationPrev = document.querySelector('[data-page="page-prev"]');
    const paginationNext = document.querySelector('[data-page="page-next"]');
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    const currentHref = window.location.href;

    paginationPrev.disabled = true;
    paginationPrev.classList.add('bg-btn-disabled');

    // disable prev/next buttons if on first/last page
    const initPrevNext = (currentPage) => {
      if (currentPage === 1) {
        paginationPrev.disabled = true;
        if (paginationPrev.classList.contains('bg-btn-disabled') == false) {
          paginationPrev.classList.add('bg-btn-disabled');
        }
      } else {
        if (paginationPrev.classList.contains('bg-btn-disabled')) {
          paginationPrev.classList.remove('bg-btn-disabled');
        }
        paginationPrev.disabled = false;
      }
      if (currentPage === numOfPages) {
        paginationNext.disabled = true;
        paginationNext.classList.add('bg-btn-disabled');
      } else {
        if (paginationNext.classList.contains('bg-btn-disabled')) {
          paginationNext.classList.remove('bg-btn-disabled');
        }
        paginationNext.disabled = false;
      }
    };
    initPrevNext(paginationObject.currentPage);

    const addActiveClass = (currentPage) => {
      // remove active class from all pagination buttons
      paginationNumbers.forEach((link) => {
        link.classList.remove('active');
      });
      // if page number is clicked set active class
      const activePage = document.querySelector(`[data-page="${currentPage}"]`);
      activePage.classList.add('active');
    };
    addActiveClass(paginationObject.currentPage);

    // add event listeners to pagination number buttons
    paginationNumbers.forEach((button) => {
      // get page number from button
      const pageIndex = parseInt(button.dataset.page);
      button.addEventListener('click', (event) => {
        event.preventDefault();
        paginationObject.currentPage = pageIndex; // set current page to page number
        addActiveClass(paginationObject.currentPage); // add active class to page number
        initPrevNext(paginationObject.currentPage); // disable prev/next buttons if on first/last page
        paginationObject.paginate(); // paginate
        window.location.href = '#scrollTo'; // scroll to top of page
        let newRelativePathQuery = window.location.pathname + '?page=' + paginationObject.currentPage; // update url
        history.pushState(null, '', newRelativePathQuery); // update url
        canonicalLink.href = updateQueryStringParameter(currentHref, 'page', paginationObject.currentPage);
      });
    });

    paginationPrev.addEventListener('click', (event) => {
      event.preventDefault();
      // set current page to page number
      paginationObject.currentPage = paginationObject.currentPage - 1;
      // disable prev/next buttons if on first/last page
      initPrevNext(paginationObject.currentPage);
      if (paginationObject.currentPage < 1) {
        paginationObject.currentPage = 1;
      }
      addActiveClass(paginationObject.currentPage);
      paginationObject.paginate();
      window.location.href = '#scrollTo';
      let newRelativePathQuery = window.location.pathname + '?page=' + paginationObject.currentPage;
      history.pushState(null, '', newRelativePathQuery);
      canonicalLink.href = updateQueryStringParameter(currentHref, 'page', paginationObject.currentPage);
    });
    paginationNext.addEventListener('click', (event) => {
      event.preventDefault();
      // set current page to page number
      paginationObject.currentPage = paginationObject.currentPage + 1;
      initPrevNext(paginationObject.currentPage);
      addActiveClass(paginationObject.currentPage);
      paginationObject.paginate();
      window.location.href = '#scrollTo';
      let newRelativePathQuery = window.location.pathname + '?page=' + paginationObject.currentPage;
      history.pushState(null, '', newRelativePathQuery);
      canonicalLink.href = updateQueryStringParameter(currentHref, 'page', paginationObject.currentPage);
    });
  },
};

paginationObject.init();
